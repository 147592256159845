import UserAuth from "../auth/userAuth.class";
import { Params } from "@angular/router";
import lodash from "lodash";
const fetchQueryParams = () => {
  return window.location.search.length === 0
    ? {}
    : lodash.trim(window.location.search, " .")
      .substr(1)
      .split('&')
      .map(pairString => pairString.split('='))
      .reduce((out, pair) => {
        out[pair[0]] = pair[1];
        return out;
      }, {} as Params);
};

export const getQueryParams = () => {
  const queryParams = fetchQueryParams();
  const userAuth = new UserAuth();
  userAuth.id = (queryParams.id == undefined) ? undefined : queryParams.id;
  userAuth.orgID = (queryParams.orgID == undefined) ? undefined : decodeURI(queryParams.orgID);
  userAuth.indID = queryParams.indKey ? queryParams.indKey : queryParams.indID;
  userAuth.designTypeId = queryParams.designTypeId;
  userAuth.workflow = queryParams.workflow;
  userAuth.referrer = queryParams.referrer
  userAuth.scanType = queryParams.scanType,
  userAuth.scannedField = queryParams.scannedField,
  userAuth.surveyId = queryParams.surveyId ? decodeURI(queryParams.surveyId) : null,
  userAuth.targetImageWidth = queryParams.targetImageWidth
  userAuth.callFrom = queryParams.callFrom,
  userAuth.isNewTab = queryParams.isNewTab,
  userAuth.factorV2 = queryParams.factorV2;
  userAuth.forgotPassword = queryParams.forgotPassword;
  userAuth.task = queryParams.task
  userAuth.maxCameraResolution = queryParams.maxCameraResolution;
  userAuth.firstName = queryParams.firstName ? decodeURI(queryParams.firstName) : null;
  userAuth.lastName = queryParams.lastName ? decodeURI(queryParams.lastName): null;
  userAuth.visitKey = queryParams.visitKey;
  userAuth.isVisitor = queryParams.isVisitor;
  userAuth.isVisitorPhotoCaptured = queryParams.isVisitorPhotoCaptured;
  userAuth.isFromStartPage = queryParams.isFromStartPage;
  userAuth.phone = queryParams.phone;
  userAuth.currentState = queryParams.currentState
  userAuth.gameId = queryParams.gameId;
  userAuth.isFrom = queryParams.isFrom;
  userAuth.temperature = queryParams.temperature;
  userAuth.maxAge = queryParams.maxAge;
  userAuth.eventId = queryParams.eventId;
  userAuth.studioID = queryParams.studioID;
  userAuth.superAdmin = queryParams.superAdmin;
  userAuth.designOrVariationId = queryParams.designOrVariationId;
  userAuth.isDesignOrVariation = queryParams.isDesignOrVariation;
  userAuth.isRecordEntryExit = queryParams.isRecordEntryExit;
  userAuth.repeatScanDelay = queryParams.repeatScanDelay;
  userAuth.scannedUserKey = queryParams.scannedUserKey;
  userAuth.landingPageKey = queryParams.landingPageKey;
  userAuth.ticketId = queryParams.ticketId;
  userAuth.seriesId = queryParams.seriesId;
  userAuth.printDesignTypeID = queryParams.printDesignTypeID;
  userAuth.printDesignID = queryParams.printDesignID;
  userAuth.isPrintDesignOrVariation = queryParams.isPrintDesignOrVariation;
  userAuth.printFF = queryParams.printFF;
  userAuth.isSelfRegistrant = queryParams.isSelfRegistrant;
  userAuth.isPhotoCaptured = queryParams.isPhotoCaptured;
  userAuth.designTypeId_Review = queryParams.designTypeId_Review;
  userAuth.designOrVariationId_Review = queryParams.designOrVariationId_Review;
  userAuth.isDesignOrVariation_Review = queryParams.isDesignOrVariation_Review;
  userAuth.passTypeID = queryParams.passTypeID;
  userAuth.visitorIndID = queryParams.visitorIndID;
  userAuth.from = queryParams.from;
  userAuth.contactDetailsPref = queryParams.contactDetailsPref;
  userAuth.digitalIDPref = queryParams.digitalIDPref;
  userAuth.auxIDPref = queryParams.auxIDPref;
  userAuth._RFIDPref = queryParams._RFIDPref;
  userAuth.sourceField = queryParams.sourceField;
  userAuth.otherIDFormat = queryParams.otherIDFormat;
  userAuth.minNumOfImages = queryParams.minNumOfImages;
  userAuth.watchMeGrowEffect = queryParams.watchMeGrowEffect;
  userAuth._2FAVerification = queryParams._2FAVerification;
  userAuth.passId = queryParams.passId;
  userAuth.attendedEventsKey = queryParams.attendedEventsKey;
  userAuth.courseKey = queryParams.courseKey;
  userAuth.kioskDataKey = queryParams.kioskDataKey;
  userAuth.payload = queryParams.payload;
  userAuth.log = queryParams.log;
  userAuth.url = queryParams.url;
  userAuth.redir = queryParams.redir;
  userAuth.timestamp = queryParams.timestamp
  userAuth.sso = queryParams.sso;
  return userAuth;
};

function formatPhoneNumber(phone) {
  if (!phone) return "";
  let formattedPhone = phone;

  // --- replace first occurrence with + because sometimes in url,
  // + and space both encoded using %20 so first occurrence is + and others are space
  if (phone.startsWith("%20")) {
    formattedPhone = phone.replace("%20", "+");
  } else if (phone.startsWith("%25")) {
    formattedPhone = phone.replace("%25", "+");
  } else if (phone.startsWith("%2B")) {
    formattedPhone = phone.replace("%2B", "+");
  }

  formattedPhone = formattedPhone.replace(/%20/gi, "");

  return formattedPhone;
}

export const setQueryParams = (user: UserAuth) => {
  // format phone
  if(user.phone) {
    user.phone = formatPhoneNumber(user.phone)
  }

  return {
    queryParams: {id: user.id || null, orgID: user.orgID, indKey: user.indID, designTypeId: user.designTypeId || null, workflow: user.workflow || null, referrer: user.referrer || null,
      targetImageWidth: user.targetImageWidth || null, callFrom: user.callFrom || null, isNewTab: user.isNewTab || null, factor2: user.factorV2 || null,forgotPassword: user.forgotPassword || null,
      task: user.task,maxCameraResolution:user.maxCameraResolution, firstName: (user.firstName) ? user.firstName : null, lastName: (user.lastName) ? user.lastName : null,
      visitKey: (user.visitKey) ? user.visitKey : null, isVisitor:user.isVisitor || null, isVisitorPhotoCaptured:user.isVisitorPhotoCaptured || null, phone: (user.phone) ? user.phone : null,
      currentState: (user.currentState) ? user.currentState : null, gameId: (user.gameId) ? user.gameId : null, isFrom: (user.isFrom) ? user.isFrom : null,
      temperature: (user.temperature) ? lodash.cloneDeep(unescape(user.temperature)) : null, maxAge: (user.maxAge) ? user.maxAge : null, surveyId : (user.surveyId) ? user.surveyId : null,
      eventId: (user.eventId) ? user.eventId : null, studioID : (user.studioID) ? user.studioID : null,superAdmin : (user.superAdmin) ? user.superAdmin : null, designOrVariationId: (user.designOrVariationId) ? user.designOrVariationId : null,
      isDesignOrVariation: (user.isDesignOrVariation) ? user.isDesignOrVariation : null, isRecordEntryExit: (user.isRecordEntryExit) ? user.isRecordEntryExit : null,
      scannedUserKey: (user.scannedUserKey) ? user.scannedUserKey : null, landingPageKey: (user.landingPageKey) ? user.landingPageKey : null,
      ticketId: (user.ticketId) ? user.ticketId : null, seriesId: (user.seriesId) ? user.seriesId : null,
      printDesignTypeID: user.printDesignTypeID || null,
      printDesignID: user.printDesignID || null,
      isPrintDesignOrVariation: user.isPrintDesignOrVariation || null,
      printFF: user.printFF || null,
      isSelfRegistrant: user.isSelfRegistrant,
      isPhotoCaptured: user.isPhotoCaptured,
      designTypeId_Review: user.designTypeId_Review || null,
      designOrVariationId_Review: user.designOrVariationId_Review || null,
      isDesignOrVariation_Review: user.isDesignOrVariation_Review || null,
      passTypeID: user.passTypeID || null,
      visitorIndID: user.visitorIndID || null,
      from: user.from || null,
      contactDetailsPref: user.contactDetailsPref || null,
      digitalIDPref: user.digitalIDPref || null,
      auxIDPref: user.auxIDPref || null,
      _RFIDPref: user._RFIDPref || null,
      sourceField: user.sourceField || null,
      otherIDFormat: user.otherIDFormat || null,
      minNumOfImages: user.minNumOfImages || null,
      watchMeGrowEffect: user.watchMeGrowEffect || null,
      _2FAVerification: user._2FAVerification || null,
      passId: user.passId || null,
      scanType: user.scanType || null,
      attendedEventsKey: user.attendedEventsKey || null,
      courseKey: user.courseKey || null,
      kioskDataKey: user.kioskDataKey || null,
      payload: user.payload || null,
      url: user.url || null,
      redir: user.redir || null,
      timestamp: user.timestamp || null,
      sso: user.sso || null
    }
  };
};

export const QueryParamsIDs = {
  SIGN_UP_VISITOR: '101',
  PICKUP_DEPENDENTS: '102',
  SELFIE_FROM_SIDEBAR_OPTION: '103',
  CONTACT_DETAILS_FROM_SIDEBAR_OPTION: '104',
  PHOTO_CAPTURE_VISITOR: '105',
  KIOSK_PHOTO_CAPTURE_IF_NO_APPROVED_PHOTO_PRESENT: '106',
  KIOSK_PHOTO_CAPTURE_IF_NOT_PRESENT: '107',
  KIOSK_PHOTO_DO_NOT_CAPTURE: '108',
  KIOSK_PHOTO_ALWAYS_CAPTURE: '109',
  GDR_DEMO_MODE: '578'
}

// function encodeKeys(s: string) {
//   // --- convert all chars to ascii
//   let ascii = s.split("").map(char => char.charCodeAt(0));

//   // --- convert all ascii to binary
//   let binary: any = ascii.map(a => a.toString(2).padStart(8, "0"));
//   binary = binary.join("");

//   // --- append extra 0s to the end for creating group of 7 (also append = sign equal to number of appended 0s)
//   let toAppendCount = 7 - (binary.length % 7);
//   let toAppendEqls = "";
//   if (toAppendCount > 0) {
//     while (toAppendCount != 0) {
//       binary = `${binary}0`;
//       toAppendEqls += "=";
//       toAppendCount--;
//     }
//     binary = `${binary}${toAppendEqls}`;
//   }
//   return binary;
// }

export function decodeKeys(s: string) {
  // --- remove appended 0s and eqls from the end
  let regExp = new RegExp(/=+$/g);
  let matches = regExp.exec(s);
  let match = matches.length > 0 ? matches[0] : "";
  let count = match.length;
  s = s.substring(0, s.length - count * 2);

  // --- devide in group of 8 and convert to decimal > character
  let chunks: any = s.match(/.{1,8}/g);
  chunks = chunks.map(bin => parseInt(bin, 2));
  chunks = chunks.map(dec => String.fromCharCode(dec));
  return chunks.join("");
}

export function getKeyFromQueryParams(kayName: string) {
  const queryParams = fetchQueryParams();
  return queryParams[kayName];
}
